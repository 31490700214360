//
// Variables Custom
// --------------------------------------------------


//== Colors
//
//## Gray and brand colors for use across Bootstrap.

$gray-base:               #959494;
$gray-darker:             #141414;
$gray-dark:               #181818;
$gray:                    #959494;
$gray-light:              #757575;
$gray-lighter:            #666666;
$gray-lightest:           #f9fbfc;
$gray-lighten:            #f8f8f8;

$primary:                 #84b315;

$white:                   #fff;
$black:                   #000;


$gray-1:                   #f2f5f8;
$gray-2:                   #a3a1a1;

$secondary-1: #2f6890;
$secondary-2: #14a5eb;
$secondary-3: #e75854;


//== Scaffolding
//
//## Settings for some of the most global styles.

//** Background color for `<body>`.
$body-bg:               #fff;
//** Global text color on `<body>`.
$text-color:            $gray-base;

//** Global textual link color.
$link-color:            $primary;
//** Link hover color set via `darken()` function.
$link-hover-color:      $gray-dark;
//** Link hover decoration.
$link-hover-decoration: none;

//== Typography
//
//## Font, line-height, and color for body text, headings, and more.

// Import fonts


$font-family-sans-serif:  'Open Sans', sans-serif;
$font-family-serif:       'Satisfy', cursive;
//** Default monospace fonts for `<code>`, `<kbd>`, and `<pre>`.
$font-family-monospace:   Menlo, Monaco, Consolas, "Courier New", monospace;


$font-family-base:        $font-family-sans-serif;
$font-family-sec:         $font-family-serif;


// Template width (w/o units)
$template-width:          2050;

// Template width var for resizing
$template-width-min:      ($template-width+1)*1px;
$layout-boxed-width:      1920px;


// Base FZ & LH (w/o units)
$base-text-size:          14;
$base-lh-size:            20;

$base-vm-size:            (($base-text-size*100)/$template-width)*1vw;


$font-size-base:          $base-text-size * 1px;
$font-size-large:         ceil(($font-size-base * 1.25)); // ~18px
$font-size-small:         ceil(($font-size-base * 0.85)); // ~12px

$font-weight-base:        400;

//PSD FZ headers /FOR EDIT (w/o units)
$Size-h1:                 60;
$Size-h2:                 48;
$Size-h3:                 36;
$Size-h4:                 24;
$Size-h5:                 24;
$Size-h6:                 18;

//PSD LH headers /FOR EDIT (w/o units)
$Lh-h1:                   60;
$Lh-h2:                   48;
$Lh-h3:                   42;
$Lh-h4:                   24;
$Lh-h5:                   30;
$Lh-h6:                   18;


//Calculating FZ headers
$font-size-h1:            $Size-h1*1px;
$font-size-h2:            $Size-h2*1px;
$font-size-h3:            $Size-h3*1px;
$font-size-h4:            $Size-h4*1px;
$font-size-h5:            $Size-h5*1px;
$font-size-h6:            $Size-h6*1px;


//** line-height for headers /FOR EDIT
// $font-lh-h_: floor((PSD l-h / $font-size-h1));

$font-lh-h1:              ($Lh-h1 / $Size-h1);
$font-lh-h2:              ($Lh-h2 / $Size-h2);
$font-lh-h3:              ($Lh-h3 / $Size-h3);
$font-lh-h4:              ($Lh-h4 / $Size-h4);
$font-lh-h5:              ($Lh-h5 / $Size-h5);
$font-lh-h6:              ($Lh-h6 / $Size-h6);

//** Unit-less `line-height` for use in components like buttons.
$line-height-base:        ($base-lh-size / $base-text-size); // l-h/fz-base
//** Computed "line-height" (`font-size` * `line-height`) for use with `margin`, `padding`, etc.
$line-height-computed:    floor(($font-size-base * $line-height-base)); // ~20px

//** By default, this inherits from the `<body>`.
$headings-font-family:    inherit;
$headings-font-weight:    400;
$headings-line-height:    1.2;
$headings-color:          $gray-dark;


//== Forms variables
//

$input-height: 50px;
$input-color: $gray-base;
$input-color-placeholder: $input-color;
$input-bg: $gray-lightest;
$input-border: $gray-lightest;
$input-border-radius: 0;
$input-font-size: 14px;
$input-line-height: 18px;
$input-font-weight: 400;
$input-padding-vertical: 10px;
$input-padding-hoizontal: 23px;
$error-color: #d9534f;
$success-color: #5cb85c;



//== Media queries breakpoints
//
//## Define the breakpoints at which your layout will change, adapting to different screen sizes.

// Extra small screen / phone
$screen-xs:                  480px;
$screen-xs-min:              $screen-xs;
$screen-phone:               $screen-xs-min;

// Small screen / tablet
$screen-sm:                  768px;
$screen-sm-min:              $screen-sm;
$screen-tablet:              $screen-sm-min;

// Medium screen / desktop
$screen-md:                  992px;
$screen-md-min:              $screen-md;
$screen-desktop:             $screen-md-min;

// Large screen / wide desktop
$screen-lg:                  1200px;
$screen-lg-min:              $screen-lg;
$screen-lg-desktop:          $screen-lg-min;

// Extra Large screen / 4k desktop
$screen-xl:                  1800px;
$screen-xl-min:              $screen-xl;
$screen-xl-desktop:          $screen-xl-min;

// So media queries don't overlap when required, provide a maximum
$screen-xxs-max:             ($screen-xs-min - 1);
$screen-xs-max:              ($screen-sm-min - 1);
$screen-sm-max:              ($screen-md-min - 1);
$screen-md-max:              ($screen-lg-min - 1);
$screen-lg-max:              ($screen-xl-min - 1);

// Define media prefix
$medias: (0: null, $screen-xs-min : xs, $screen-sm-min : sm, $screen-md-min : md, $screen-lg-min : lg, $screen-xl-min : xl);


//== Flex Grid system
//
//## Define your custom flex responsive grid.

$shell-width:         300px;
$shell-xs-width:      480px;
$shell-sm-width:      750px;
$shell-md-width:      970px;
$shell-lg-width:      1200px;
$shell-xl-width:      1800px;

$range-ws:            50px;
$range-ws-sm:         30px;
$range-ws-lg:         66px;

$cell-count:          12;
$cell-spacing:        30px;
$cell-spacing-narrow: 10px;

//== Shadows
//
//##

$shadow-area-ambient: 0px 0px 1px 0 rgba(0, 0, 0, 0.15);
$shadow-area-xxs: 0 3px 6px rgba(0, 0, 0, 0.1);
$shadow-area-xs: 0 0px 5px 0 rgba(0, 0, 0, 0.30);
$shadow-area-sm: 0 0px 7px 0 rgba(0, 0, 0, 0.13);
$shadow-area-md: 0 3px 11px 0 rgba(0, 0, 0, 0.15);
$shadow-area-lg: 0 5px 23px 0 rgba(0, 0, 0, 0.15);
$shadow-area-xl: 0 12px 36px 0 rgba(0, 0, 0,.15);

