// Blockquotes
blockquote {
  q{
    &:before,
    &:after{ content: none; }
  }
  cite { font-style: normal; }
}

.quote-variant-1{
  border-left: 0;
  margin: 0;
  padding-left: 0;
  font-size: 14px;
  text-align: left;
  q{
    font-weight: 300;
    font-style: italic;
  }

  cite{
    display: block;
    font: 600 18px/24px $font-family-base;
    text-transform: uppercase;
    color: $gray-dark;

    + * {
      margin-top: 6px;
      margin-bottom: 7px;
    }
    + p {
      font-weight: 400;
    }
  }

  .quote-body {
    margin-top: 22px;
  }
}

%quote {
  .quote-body {
    font:400 40px/1 $font-family-sec;

    @media (min-width: $screen-sm-min) {
      font-size: 60px;
    }

    q{
      quotes: "\201C" "\201E" ;
      &:before,
      &:after {
        font-size: 100px;
        line-height: 0;
      }
      &:before {
        margin-left: -20px;
      }
    }
  }
  .quote-cite {
    span {
      display: block;
      font-weight: 600;
      &:first-of-type {
        font-size: 18px;
      }
    }
  }
}

.quote-variant-2 {
  @extend %quote;

  * + .quote-cite {
    margin-top: 30px;
  }
}

.quote-variant-3 {
  @extend %quote;

  .quote-body {
    position: relative;
    font:400 18px/1.35 $font-family-base;
    q {
      &:after,
      &:before {
        font-family: $font-family-sec;
      }
      &:before{
        position: absolute;
        top: 25px;
        left: 0;
        @media (min-width: $screen-sm-min) {
          left: -7px;
        }
      }
       &:after{
         font-size: 48px;
       }

      span:first-of-type{
        font:400 40px/1 $font-family-sec;
        display: inline-block;
        margin-bottom: 20px;
        opacity: 1;

        @media (min-width: $screen-sm-min) {
          font-size: 48px;
        }

        @media (min-width: $screen-lg-min) {
          margin-bottom: 35px;
        }
      }
      @media (min-width: $screen-lg-min) {
        span:last-of-type {
          opacity: .6;
        }
      }
    }
  }

  .quote-cite span:last-of-type {
    font-weight: 400;
  }

  * + .quote-cite {
    margin-top: 15px;
  }
  * + .button {
    margin-top: 15px;
  }
}

%quote-class {
  text-align: center;

  q {
    font-style: italic;
  }
  .quote-cite {
    span {
      display: block;
      color: $gray-dark;
      font-weight: 600;
      &:first-of-type {
        font-size: 18px;
      }
    }
  }
}
.quote-classic {
  @extend %quote-class;

  q{
    quotes: "\201E" "";
    &:before {
      display: block;
      height: 30px;
      font:400 60px/0 $font-family-sec;
    }
  }

  * + .quote-img {
    margin-top: 20px;
  }

  * + .quote-cite {
    margin-top: 18px;
  }
}

.quote-classic-variant-1 {
  @extend %quote-class;
  text-align: left;

  q {
    font-style: normal;
    quotes: "" "";
  }
  * + .quote-body {
    margin-top: 18px;
  }
}

.quote-classic-big {
  @extend %quote-class;
  
  .mac-os & {
    q {
      @media (min-width: $screen-lg-min) {
        padding-right: 15px;
      }
    }
  }

  q{
    position: relative;
    display: flex;
    align-items: center;
    quotes: "\201C" "\201E";
    font-size: 18px;
    max-width: 670px;
    margin-left: auto;
    margin-right: auto;
    &:before,
    &:after {
      height: 30px;
      flex-basis: 50px;
      font:400 100px/1 $font-family-sec;
    }

    &:before{
      margin-right: 10px;
    }
     &:after{
       line-height: 0;
       margin-top: -15px;
       margin-left: 10px;
     }
    @media (min-width: $screen-sm-min) {
      &:before {
        margin-right: 20px;
      }
      &:after{
        margin-left: 20px;
      }
    }
  }

  * + .quote-img {
    margin-top: 45px;
  }
  * + .quote-cite {
    margin-top: 15px;
  }
  
  //IE fix
  .ie-11 &,
  .ie-10 &,
  .ie-edge & {
    q{
      &:before,
      &:after {
        display: block;
      }
      &:before {
        margin-right: 40px;
      }
    }
  }
}

