/* 
 *  Owl Carousel - Animate Plugin
 */
.owl-carousel .animated {
  -webkit-animation-duration: 1000ms;
  animation-duration: 1000ms;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.owl-carousel .owl-animated-in {
  z-index: 0;
}

.owl-carousel .owl-animated-out {
  z-index: 1;
}

.owl-carousel .fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut;
}

@-webkit-keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

/* 
 * 	Owl Carousel - Auto Height Plugin
 */
.owl-height {
  transition: height 500ms ease-in-out;
}

/* 
 *  Core Owl Carousel CSS File
 */
.owl-carousel {
  display: none;
  width: 100%;
  -webkit-tap-highlight-color: transparent;
  /* position relative and z-index fix webkit rendering fonts issue */
  position: relative;
  z-index: 1;
}

.owl-carousel .owl-stage {
  position: relative;
  -ms-touch-action: pan-Y;
}

.owl-carousel .owl-stage:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}

.owl-carousel .owl-stage-outer {
  position: relative;
  overflow: hidden;
  /* fix for flashing background */
  -webkit-transform: translate3d(0px, 0px, 0px);
}

.owl-carousel .owl-nav .owl-prev,
.owl-carousel .owl-nav .owl-next,
.owl-carousel .owl-dot {
  cursor: pointer;
  cursor: hand;
  user-select: none;
}

.owl-carousel.owl-loaded {
  display: block;
}

.owl-carousel.owl-loading {
  opacity: 0;
  display: block;
}

.owl-carousel.owl-hidden {
  opacity: 0;
}

.owl-carousel .owl-refresh .owl-item {
  display: none;
}

.owl-carousel .owl-item {
  position: relative;
  min-height: 1px;
  float: left;
  -webkit-backface-visibility: hidden;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  user-select: none;

  > .owl-item {
    width: 100%;
    text-align: center;
    img{
      //width: 100%;
    }
  }
}

.owl-carousel.owl-text-select-on .owl-item {
  user-select: none;
}

.owl-carousel .owl-grab {
  cursor: move;
  cursor: -webkit-grab;
  cursor: grab;
}

.owl-carousel.owl-rtl {
  direction: rtl;
}

.owl-carousel.owl-rtl .owl-item {
  float: right;
}

/* No Js */
.no-js .owl-carousel {
  display: block;
}

/* 
 * 	Owl Carousel - Lazy Load Plugin
 */
.owl-carousel .owl-item .owl-lazy {
  opacity: 0;
  transition: opacity 400ms ease;
}

/* 
 * 	Owl Carousel - Video Plugin
 */
.owl-carousel .owl-video-wrapper {
  position: relative;
  height: 100%;
  background: #000;
}

.owl-carousel .owl-video-play-icon {
  position: absolute;
  height: 80px;
  width: 80px;
  left: 50%;
  top: 50%;
  margin-left: -40px;
  margin-top: -40px;
  font: 400 40px/80px 'FontAwesome';
  cursor: pointer;
  z-index: 1;
  -webkit-backface-visibility: hidden;
  transition: scale 100ms ease;
  &:before {
    content: '\f144';
  }
}

.owl-carousel .owl-video-play-icon:hover {
  -webkit-transform: scale(1.3);
  transform: scale(1.3);
}

.owl-carousel .owl-video-playing .owl-video-tn,
.owl-carousel .owl-video-playing .owl-video-play-icon {
  display: none;
}

.owl-carousel .owl-video-tn {
  opacity: 0;
  height: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  transition: opacity 400ms ease;
}

.owl-carousel .owl-video-frame {
  position: relative;
  z-index: 1;
}

/*
 * Owl Navigation
 */
.owl-nav {
  margin-top: 30px;
  @media (min-width: 768px) {
    margin-top: 50px;
  }
}

.owl-nav {
  position: relative;
  height: 50px;
}

.owl-nav.disabled {
  display: none;
}

.owl-prev, .owl-next {
  position: absolute;
  margin-left: 5px;
  margin-right: 5px;
  padding: 0 13px;
  height: 50px;
  overflow: hidden;
  font-size: 14px;
  font-weight: 600;
  line-height: 1;
  color: $primary;
  background-color: $white; 

  span {
    display: inline-block;
    width: 0;
    overflow: hidden;
    transition: 250ms ease-in;
    vertical-align: middle;
  }

  &:before,
  &:after {
    font: 400 24px/50px 'Material Icons';
    vertical-align: middle;
  }

  &:hover {

    color: $white;
    background-color: $primary;
    span {
      width: 35px;
    }
  }
}

.owl-prev {
  right: 50%;
  &:before {
    content: '\e5cb';
  }
  @media (min-width: $screen-sm-min) {

  }
}

.owl-next {
  left: 50%;
  &:after {
    content: '\e5cc';
  }
  @media (min-width: $screen-sm-min) {

  }
}

/*
 * Owl Pagination
 */

.owl-dots {
  text-align: center;
  margin-top: 10px;
}

.owl-dots.disabled {
  display: none;
}

.owl-dot {
  display: inline-block;
  text-align: center;
  border-radius: 50%;
  position: relative;
  margin-left: 5px;
  width: 11px;
  height: 11px;
  outline: none;
  cursor: pointer;
  background-color: $primary;
  box-shadow: inset 0 0 0 2px $primary;
  transition: box-shadow 0.3s ease;

  &:hover,
  &:focus {
    box-shadow: inset 0 0 0 2px rgba($gray-base, 0.6);
    background-color: $gray-base;
  }

  &.active {
    box-shadow: inset 0 0 0 6px $gray-base;
  }
}

//custom
.owl-carousel-gallery {
  .owl-nav {
    margin-top: 0;
  }
  .owl-nav {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
  }

  .owl-prev {
    right: auto;
    left: -5px;
  }

  .owl-next {
    left: auto;
    right: -5px;
  }
}

.owl-carousel-custom-pagination {
  .owl-nav {
    display: none;
  }
}

.owl-controls-custom {
  * + & {
    margin-top: 25px;
  }
}

.owl-nav-custom {
  * + & {
    margin-top: 25px;
  }
}

//flickr
.owl-carousel.flickr {
  .owl-nav {
    margin-top: 0;
  }
  .owl-nav {
    position: static;
    height: auto;
  }
  .owl-prev,
  .owl-next {
    position: absolute;
    top: 0;
    bottom: 4px;
    height: auto;
    display: flex;
    align-items: center;
    background-color: rgba($gray-dark,.3);
    color: $white;

    &:before,
    &:after {
      font-size: 36px;
    }
    
    &:hover{
      color: $primary;   
    }
  }

  .owl-prev {
    right: auto;
    left: -5px;
  }

  .owl-next {
    left: auto;
    right: -5px;
  }
}

//custom navigation

.owl-carousel-custom-navigation {
  .owl-prev, .owl-next {
    position: static;
    display: inline-block;
  }
}