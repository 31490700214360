//
// RD Navbar sidebar, style by fixed layout on all screen sizes
// --------------------------------------------------
html.rd-navbar-sidebar-linked {
  body {

    .rd-navbar-wrap {
      position: fixed;
      z-index: 9;
    }
    .page {
      padding-right: 320px;
    }
  }
}

.header-sidebar {

  .rd-navbar-aside-left {
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 20px;
    margin-top: 50px;
    text-align: left;
    > * + * {
      margin-top: 50px;
    }
  }
  
  .rd-navbar-megamenu {
    h6 {
      font-size: 17px;
      font-weight: 600;
    }
    > li + li {
      margin-top: 10px;
    }
  }

  &.rd-navbar-fixed {

    .rd-navbar-toggle {
      @include make-toggle(
              $rd-navbar-toggle-preset,
              48px,
              24px,
              $rd-navbar-fixed-toggle-color
      );
      position: fixed;
      top: 4px;
      right: 4px;
      left: auto;
    }

    .rd-navbar-nav-wrap {
      left: auto;
      right: 0;
      transform: translateX(120%);
      &.active {
        transform: translateX(0);
      }
    }

    .rd-navbar-nav {
      height: auto;
      li {
        a {
          color: $primary;
        }
        > .rd-navbar-submenu-toggle {
          color: $primary;
        }
      }
      // Open and hover state
      li.active,
      li.opened,
      li:hover {
        > a {
          background: $primary;
          color: $white;
        }
        > .rd-navbar-submenu-toggle {
          color: $white;
        }
      }
    }

  }

  @media (min-width: $screen-lg-min) {

    &.rd-navbar-fixed {

      .rd-navbar-brand {
        display: none;
      }

      .rd-navbar-toggle {
        width: 50px;
        height: 50px;
        background-color: $white;
        border-radius: 50%;
      }

      .rd-navbar-panel {
        background: transparent;
        box-shadow: none;
      }

      .rd-navbar-nav {
        li {
          a {
            font-size: 16px;
          }
        }
        // Open and hover state
        li.active,
        li.opened,
        li:hover {
          > a {
            background: transparent;
            color: $gray-dark;
            text-decoration: underline;
          }
          > .rd-navbar-submenu-toggle {
            color: $gray-dark;
          }
        }
      }
      .rd-navbar-nav-wrap {
        .rd-navbar-brand {
          display: block;
        }
      }
    }
  }
}

.rd-navbar-sidebar {
  @extend %rd-navbar-transition;
  position: fixed;
  display: block;
  overflow: auto;
  width: 320px;
  height: 100%;
  right: 0;
  top: 0;
  -webkit-overflow-scrolling: touch;
  font-size: 16px;
  font-weight: 600;
  line-height: 34px;
  color: $gray-dark;
  background: $rd-navbar-fixed-bg;
  z-index: 998;
  padding: 10px 0 10px 64px;
  text-align: left;

  &:before,
  &:after {
    content: '';
    display: block;
    height: 42px;
  }

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: lighten($rd-navbar-fixed-bg, 10%);
    border: none;
    border-radius: 0;
    opacity: .2;
  }

  &::-webkit-scrollbar-track {
    background: $rd-navbar-fixed-bg;
    border: none;
    border-radius: 0;
  }

  .rd-navbar-panel .rd-navbar-brand {
    display: none;
  }

  // Nav wrap
  .rd-navbar-nav-scroll-holder {
    padding-left: 10px;
    padding-right: 10px;
  }

  //nav
  .rd-navbar-nav {
    margin: 45px 0;
    height: auto;
    overflow-y: hidden;
    text-align: left;

    li {
      a {
        display: inline-block;
        padding: 3px 0 3px 0;
      }
    }
    // Open and hover state
    li.active,
    li.opened {
      > a {
        background: transparent;
        color: $gray-dark;
        text-decoration: underline;
      }
      > .rd-navbar-submenu-toggle {
        color: $gray-dark;
      }
    }
  }


  .rd-navbar-aside-left {
    padding-left: 0;
  }

  // Mobile submenu toggle
  .rd-navbar-submenu {
    position: relative;

    .rd-navbar-dropdown {
      li a{
        padding: 5px 15px;
      }
    }
  }

  .rd-navbar-submenu-toggle {
    cursor: pointer;
    position: absolute;
    top: -2px;
    right: 0;
    width: 45px;
    height: 44px;
    font-size: 20px;
    line-height: 43px;
    font-family: 'FontAwesome';
    transition: .3s all ease;
    text-align: center;

    &:before {
      content: '\f107';
      display: block;
      vertical-align: middle;
    }
  }

  // mobile dropdowns
  .rd-navbar-megamenu,
  .rd-navbar-dropdown {
    display: none;
    padding: 5px 0;

    > li {
      > a {
        padding-left: 27px;
      }
      .rd-navbar-dropdown > li {
        > a {
          padding-left: 42px;
        }
        .rd-navbar-dropdown > li > a {
          padding-left: 50px;
        }
      }
    }
  }

  // megamenu
  .rd-navbar-megamenu {
    h6 + * {
      margin-top: 3px;
    }
    > li a {
      padding-left: 35px;
    }
  }

  // Open dropdowns
  .rd-navbar-submenu.opened {
    > .rd-navbar-megamenu,
    > .rd-navbar-dropdown {
      display: block;
    }
  }

  .copyright-sidebar {
    font-size: 14px;
    font-weight: 400;
    line-height: 1.4;
  }

  @media (min-width: $screen-lg-min) {

    .rd-navbar-nav-scroll-holder {
      padding: 60px 30px 60px;
    }
  }

}