//banner box
.banner-box {
  .heading-6 {
    [href*='callto:'] {
      font-size: 24px;
    }
  }
}

%banner-classic {
  display: flex;
  align-items:center;
  justify-content: center;
  flex-direction: column;
  padding: 30px;
  text-align: center;

  .button-primary {
    @include btn-variant($white, transparent, $white, $primary, $white, $white);
    border-width: 2px;
  }

  h2,
  p {
    color: $white;
  }

  @media (min-width: $screen-md-min) {
    //height: 100%;
    width: 100%;
  }
  @media (min-width: $screen-lg-min) {
    min-height: 550px;
  }
  
  .mac-os & {
    @media (min-width: $screen-md-min) {
      height: auto;
    }
  }
}

.banner-box-classic {
  @extend  %banner-classic;
  background: url("../images/bg-banner-box-classic.jpg") no-repeat center center ;
  background-size: cover;
  pointer-events: none;
  
  > * {
    pointer-events: auto;
  }

  * + * {
    margin-top: 10px;
  }
  h2,
  p {
    color: $white;
  }

  .big {
    text-transform: uppercase;
    letter-spacing: 0.15em;
  }

  [data-x-mode="true"] & {
    pointer-events: auto;
  }
}

.banner-box-classic-extra {
  @extend  %banner-classic;
  background: url("../images/bg-banner-box-classic-extra.jpg") no-repeat center center ;
  background-size: cover;

  p {
    max-width: 650px;
  }

  @media (min-width: $screen-lg-min) {
    * + p {
      margin-top: 45px;
    }
  }
}
%banner-box-classic-small{
  min-height: 340px;
  h2 + p {
    margin-top: 8px;
  }
  p + .button {
    margin-top: 10px;
  }
  .big {
    text-transform: uppercase;
    letter-spacing: 0.15em;
    width: 100%;
  }
  @media (min-width: $screen-md-min) {
    border-left: 1px solid $white;
  }
}
.banner-box-classic-var-1,
.banner-box-classic-var-2,
.banner-box-classic-var-3 {
  width: 100%;
  background-size: cover;
  pointer-events: none;
  
  > * {
    pointer-events: auto;
  }

  [data-x-mode="true"] & {
    pointer-events: auto;
  }
}
.banner-box-classic-var-1 {
  @extend  %banner-classic;
  @extend  %banner-box-classic-small;
  background-image: url("../images/bg-banner-box-classic-var-1.jpg");
  
}
.banner-box-classic-var-2 {
  @extend  %banner-classic;
  @extend  %banner-box-classic-small;
  background-image: url("../images/bg-banner-box-classic-var-2.jpg");
}
.banner-box-classic-var-3 {
  @extend  %banner-classic;
  @extend  %banner-box-classic-small;
  background-image: url("../images/bg-banner-box-classic-var-3.jpg");
}

//banner quote
%banner-quote {
  display: flex;
  align-items: center;
  padding: 50px 20px;
  background-repeat: no-repeat;
  background-size: cover;

  @media (min-width: 768px) {
    padding: 65px 85px 80px;
  }
}



.banner-box-quote {
  @extend %banner-quote;
  justify-content: flex-end;
  background-image: url("../images/bg-banner-box-quote.jpg");

  .quote-variant-2 {
    color: $white;
    max-width: 530px;
  }
}

.banner-box-quote-primary {
  @extend %banner-quote;

  position: relative;
  background-color: $primary;


  &:before{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    content: "";
    display: inline-block;
    background-color: rgba($primary,.75);
  }


  @media (min-width: 768px) {
    padding: 65px 40px 90px;
  }

  @media (min-width: $screen-lg-min) {
    &:before{
      content: none;
    }
  }

  .quote-variant-3 {
    position: relative;
    color: $white;
    max-width: 530px;
  }

  .button-primary {
    @include btn-variant($primary, $white, $white, $white, $primary, $white);
  }

  &-right {
    justify-content: flex-end;
    background-image: url("../images/bg-banner-box-quote-primary.jpg");
  }
  &-left {
    justify-content: flex-start;
    background-image: url("../images/bg-banner-box-quote-primary-right.jpg");
    @media (min-width: $screen-xl-min) {
      padding-left: 55px;
    }
  }
}

.banner-box-base {
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  min-height: 301px;
  width: 100%;
  padding: 30px 15px;
  background-color: $gray-lightest;

  > * + * {
    margin-top: 15px;
  }
  
  @media (min-width: 768px) {
    > * + * {
      margin-top: 0;
    }
  }
  
  @media (min-width: 992px) {
    padding:30px 60px;
  }

  .banner-box-promo {
    padding: 15px;
    background-color: $primary;
    color: $white;

    @media (min-width: 768px) {
      position: absolute;
      top: -150px;
      right: -150px;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: flex-end;
      width: 300px;
      height: 300px;

      @include transform(rotate(45deg));
    }

    span {
      display: block;
      font-weight: 600;
      text-transform: uppercase;
      &:first-of-type {
        font-size: 24px;
      }
    }
  }

  .banner-box-inner {
    max-width: 425px;

    > * + * {
      margin-top: 10px;
    }
  }

  .subtitle {
    font-size: 24px;
    line-height: 1;
    color: $primary;
  }
  
  .title {
    font-family:$font-family-sec;
    font-size: 48px;
    line-height: 1;
    color: $gray-dark;
  }
  
  .body-text {
    font-weight: 600;
    font-size: 18px;
  }

  &.banner-box-primary {
    background-color: $primary;

    @media (min-width: 1200px) {
      background-image: url("../images/bg-banner-box-1.jpg");
      background-repeat: no-repeat;
      background-size: cover;
    }

    * {
      color: $white;
    }

    .banner-box-inner {
      max-width: 330px;
    }
    .body-text {
      font-weight: 400;
    }
  }

  &-mod-1 {
    > * {
      width: 100%;
      flex-grow: 1;
    }
    @media (min-width: 992px) {
      padding: 0 15px;
    }

    h3 {
      font-weight: 400;
      color: $gray-base;

      & + * {
        margin-top: 15px;
      }
    }

    .big {
      text-transform: uppercase;
      font-weight: 600;
      letter-spacing: 0.075em;
    }
    * + .big {
      margin-top: 15px;
    }
  }
  
  &-mod-2 {
    flex-direction: column;
    justify-content: center;

    @media (min-width: $screen-sm-min) {
      flex-direction: row;
      justify-content: flex-start;
    }
  }

  &.banner-box-image {
    border: 1px solid $white;
    width: 100%;
    display: flex;
    align-items: center;

    .unit-body {
      max-width: 500px;
    }
    
    @media (max-width: $screen-md-min - 1px) {
      img {
        max-height: 100px;
        width: auto;
      }
    }

    @media (min-width: $screen-md-min) {
      align-items: flex-start;
      padding: 25px 32px 25px 45px;
    }
    
    @media (min-width: $screen-lg-min) {
      .unit-body {
        width: 100%;
      }
    }
    
    .subtitle {
      font-size: 14px;
    }
    * + h3 {
      margin-top: 4px;
    }
    * + .button {
      margin-top: 10px;
    }
    h3 + p {
      margin-top: 12px;
    }
    p {
      line-height: 20px;
    }
  }
}

.banner-box-base-carousel {
  padding: 30px 20px;

  @media (min-width: $screen-sm-min) {
    padding-top: 80px;
    padding-bottom: 55px;
  }
  @media (min-width: $screen-md-min) {
    background: $gray-lightest url("../images/banner-box-base-carousel.jpg") no-repeat center center;
    background-size: cover;
  }
}

//banner box base row
.banner-box-base-row {
  .banner-box-base {
    padding-right: 15px;
  }
}

.banner-box__style-1 {
  min-height: 340px;
  align-items: center;
}


.banner-box__style-2 {
  width: 100%;
  @media (min-width: $screen-lg-min) {
    min-height: 440px;
  }
}