//
// Responsive unit
// --------------------------------------------------

@include unit-responsive($medias, 20px, 30px);

// Unit Spacing
//

.unit-spacing-xxs {
  @include unit-spacing($medias, 2px, 4px);
}

.unit-spacing-xs {
  @include unit-spacing($medias, 7px, 7px);
}

.unit-spacing-sm {
  @include unit-spacing($medias, 10px);
}

.unit-spacing-md {
  @include unit-spacing($medias, 25px);
}

.unit-spacing-xl {
  @include unit-spacing($medias, 50px);
}

.unit-spacing-xxl {
  @include unit-spacing($medias, 40px);
  @media (min-width: $screen-xl-min) {
    @include unit-spacing($medias, 60px);
  }
}