//
// Links
// --------------------------------------------------
.link {
  display: inline;
  transition: 250ms ease-in;

  &.hover {
    color: $gray-dark;
  }

  &.active {
    color: $gray-base;
  }
}

// Sizing
.link-sm {
  font-size: 18px;
}
// Styles
.page {
  .link-default {
    @include link($gray-dark, $primary);
  }

  .link-primary {
    @include link($primary, $gray-dark);
  }

  .link-white {
    @include link($white, $link-hover-color);
  }

  .link-gray-base {
    @include link($gray-base, $primary);
  }

}

// link icon
.link-icon {
   &:after{
     padding-left: 5px;
     content: '\e5cc';
     font: 400 24px/24px "Material icons";
     vertical-align: middle;
   }
}

//link img
.link-img {
  will-change: transform ;
  &:hover{
   @include transform(scale(0.85));
  }
}