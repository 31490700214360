//
// Buttons
// --------------------------------------------------

// Button General Styles
.button {
  display: inline-block;
  position: relative;
  // btn-size($padding-vertical, $padding-horizontal, $font-size, $line-height, $border-radius)
  @include btn-size(15px, 30px, 24px, 24px, 0);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  border-style: solid;
  border-width: 1px;
  text-align: center;
  cursor: pointer;
  vertical-align: middle;
  user-select: none;
  font-weight: 400;
  transition: 250ms all ease-in-out;

  &:focus,
  &:active,
  &:active:focus {
    outline: none;
  }
}

// Appearance style
// --------------------------------------------------

.button-default {
  @include btn-variant($gray-dark, $gray-lightest, $gray-lightest, $white, $gray-dark, $gray-dark);
}

.button-gray {
  @include btn-variant($primary, $gray-lightest, $gray-lightest, $white, $gray-dark, $gray-dark);
}

.button-dark {
  @include btn-variant($white, $gray-dark, $gray-dark, $white, $primary, $primary);
}

.button-primary {
  @include btn-variant($white, $primary, $primary, $white, darken($primary, 15%), darken($primary, 15%));
}

.button-secondary-1 {
  @include btn-variant($white, $secondary-1, $secondary-1, $white, darken($secondary-1, 15%), darken($secondary-1, 15%));
}

.button-secondary-2 {
  @include btn-variant($white, $secondary-2, $secondary-2, $white, darken($secondary-2, 15%), darken($secondary-2, 15%));
}

.button-secondary-3 {
  @include btn-variant($white, $secondary-3, $secondary-3, $white, darken($secondary-3, 15%), darken($secondary-3, 15%));
}

// Btn with shadow
.button-shadow{
  box-shadow: 0 3px 11px 0 rgba(0, 0, 0, 0.15);

  &:hover{
    box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.15);
  }
}

// Button Sizes
// --------------------------------------------------

.button-xs {
  // btn-size($padding-vertical, $padding-horizontal, $font-size, $line-height, $border-radius)
  @include btn-size(12px, 23px, 14px, 14px, 0);
  font-weight: 600;
}

.button-sm {
  // btn-size($padding-vertical, $padding-horizontal, $font-size, $line-height, $border-radius)
  @include btn-size(17px, 25px, 14px, 14px, 0);
  border-width: 1px;
  font-weight: 600;
}

.button-lg {
  // btn-size($padding-vertical, $padding-horizontal, $font-size, $line-height, $border-radius)
  @include btn-size(20px, 30px, 24px, 24px, 0);
}

// Button Shapes
// --------------------------------------------------

.button-circle {
  border-radius: 30px;
}

.button-ellipse {
  &-md {
    border-radius: 5px;
  }

  &-lg {
    border-radius: 10px;
  }
}

// Button Icon styles
// --------------------------------------------------

.button {

  &.button-icon {
    padding-left: 17px;
    padding-right: 17px;

    .icon {
      font-size: 16px;
      line-height: inherit;
      vertical-align: middle;
      transition: 0s;
      color: inherit;
    }
    .icon-md {
      height: 12px;
      font-size: 24px;
    }

    &-left {
      .icon {
        float: left;
        padding-right: 10px;
      }
    }

    &-right {
      .icon {
        float: right;
        padding-left: 10px;
      }
    }
  }
}

.button-link {
  display: inline-block;
  font-weight: 600;
}

.button-link-icon {
  display: inline-block;
  padding-left: 5px;
  padding-right: 5px;
  line-height: 25px;
  &:after {
    content: '\e5cc';
    font: 400 18px/21px "Material Icons";
    vertical-align: middle;
  }
}