//
// RD Navbar components
// --------------------------------------------------

// RD Navbar Toggle

.rd-navbar-toggle {
  // make toggle, but only before 1199, because on navbar-sidebar we need restyle toggle
  @media (max-width: $screen-md-max) {
    @include make-toggle(
            $rd-navbar-toggle-preset,
            48px,
            24px,
            $rd-navbar-fixed-toggle-color
    );
    display: none;
    position: fixed;
    top: 4px;
    left: 4px;
  }
  display: none;
  background-color: transparent;
  border: none;

  &:focus {
    outline: none;
  }
}

// Make search toggle
.rd-navbar-search-toggle {
  @include toggle-icons-via-rotation(48px, 48px, 20px, $rd-navbar-fixed-toggle-color, '\f002', '\f00d', 'FontAwesome', 'FontAwesome');
  transition: .2s ease-in;
}

.rd-navbar-search-button {
  position: absolute;
  top: 9px;
  right: 15px;
  width: 25px;
  height: 25px;
  background: $white;
  color: $primary;
  transition: 250ms ease-in;
  outline: none;
  border:0;
  &:before{
    content: "\f002";
    display: inline-block;
    font: 400 18px/10px "FontAwesome";
  }
  &:hover{
    color: $primary;
  }
}

// Collapse toggle
.rd-navbar-collapse-toggle {
  @include make-toggle(
          $rd-navbar-collapse-toggle-preset,
          48px,
          20px,
          $rd-navbar-fixed-toggle-color
  );
  background-color: transparent;
  border: none;
  display: none;

  &:focus {
    outline: none;
  }
}

// Collapse item style on fixed and fullwidth navbar
.rd-navbar-fixed,
.rd-navbar-fullwidth {

  .rd-navbar-collapse-toggle {
    display: block;
  }

  .rd-navbar-collapse-items {
    opacity: 0;
    visibility: hidden;
    right: 15px;
    width: 275px;
    padding: 20px;
    z-index: 20;
    box-shadow: 0 3px 11px 0 rgba(0, 0, 0, 0.15);
    transition: all .3s ease;
    color: $gray-base;

    // Inside collapse appearance style
    .list-inline {
      margin-top: 5px;
      text-align: left;
      li + li {
        margin-top: 5px;
      }
    }

    .icon {
      color: $gray-base;
    }

    a {
      color: $gray-base;

      &:hover {
        color: $gray-light;
      }
    }

    //active state
    &.active {
      opacity: 1;
      visibility: visible;
    }
  }
}

//collapse item on fixed
.rd-navbar-fixed {
  .rd-navbar-collapse-toggle {
    position: fixed;
    top: 4px;
    right: -4px;
  }

  .rd-navbar-collapse-items {
    position: fixed;
    top: 60px;
    background: $rd-navbar-fixed-bg;
  }
}

// collapse item on fullwidth
.rd-navbar-fullwidth {
  .rd-navbar-collapse-toggle {
    position: absolute;
    right: 15px;
    top: 10px;
  }

  .rd-navbar-collapse-items {
    position: absolute;
    top: 50px;
    background: $rd-navbar-fixed-bg;
  }
}

// RD Navbar Brand
.rd-navbar-brand {
  a {
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: $primary;
    vertical-align: middle;

    &:hover {
      color: $primary;
    }
  }

  img {
    max-width: 180px;
  }
}

// Mobile brand
.rd-navbar-mobile-brand {
  display: none;
  img {
    max-width: 100px;
  }
}

// RD Navbar Dropdown
.rd-navbar-dropdown {
  display: none;
}

// General style for rd-navbar-inner and top panel
.rd-navbar-top-panel-inner,
.rd-navbar-inner {
  max-width: $rd-navbar-width;
  margin-left: auto;
  margin-right: auto;
}

// Placeholders
//------------------------

// Mobile search
%rd-default-mobile-search {

  //Disable livesearch
  #rd-search-results-live {
    display: none;
  }

  //Search toggle
  .rd-navbar-search-toggle {
    position: fixed;
    top: 4px;
    z-index: 20;
    right: 1px;
    height: 48px;
  }

  // Search form
  .rd-search {
    position: fixed;
    top: 6px;
    z-index: 999;
    right: 47px;
    left: 15px;
    opacity: 0;
    pointer-events: none;
    transition: .2s ease-in-out;

    .form-label {
      font-style: italic;
    }

    .form-input {
      border: none;
      padding-right: 45px;
    }
  }

  // Active state
  .rd-navbar-panel.active {

    .rd-navbar-brand,
    .rd-navbar-collapse-toggle,
    .rd-navbar-toggle {
      opacity: 0;
      pointer-events: none;
    }
  }

  .rd-navbar-search.active {
    .rd-search {
      opacity: 1;
      pointer-events: all;
    }
    .rd-navbar-search-toggle {
      right: 1px;
    }
  }
}

// Default desktop search
%rd-navbar-default-search {

  // Navbar aside right
  .rd-navbar-aside-right {
    position: relative;
  }

  // Search form
  .rd-search {
    position: absolute;
    top: 0;
    left: 0;
    right: 24px;
    opacity: 0;
    pointer-events: none;
    transition: .2s ease-in-out;

    .form-label {
      font-style: italic;
    }

    .form-input {
      border: none;
      border-bottom: 1px solid $gray-lighter;
    }
  }

  // Active state
  .rd-navbar-panel.active {

    .rd-navbar-nav {
      opacity: 0;
      pointer-events: none;
    }
  }

  .rd-navbar-search.active {
    .rd-search {
      opacity: 1;
      pointer-events: all;
      z-index: 9;
    }
  }
}

// Default navbar first level navigation
%rd-navbar-default-menu-first-lvl {
  .rd-navbar-nav {
    @include clearfix;
    display: inline-block;

    > li {
      position: relative;
      display: inline-block;
      // padding need for builder
      padding-left: 1px;
      padding-right: 1px;
      transition: 250ms;
      vertical-align: middle;

      @media (min-width: $screen-lg-min) {
        padding: 19px 6px 1px;

        &.rd-navbar-brand{
          padding: 1px 1px;
        }
      }

      > a {
        @extend %rd-navbar-default-link-font;
        display: inline-block;
        position: relative;
        padding: 12px 18px;
        color: $primary;
        transition: .3s ease-in-out;

        &.brand-name{
          padding: 0;
          &:hover{
           background: transparent;
          }
        }
      }

      &.active,
      &.focus {
        > a {
          color: $white;
          background: $primary;
        }
      }

      a {
        &:hover {
          color: $white;
          background: $primary;
        }
      }

      &.rd-navbar-submenu {
        > a {
          //&:after {
          //  content: '\f107';
          //  position: absolute;
          //  top: 50%;
          //  right: 6px;
          //  left: auto;
          //  font: 16px/20px "FontAwesome";
          //  transform: translateY(-50%);
          //  transition: .3s transform ease-in-out;
          //}
        }
      }

      &.focus > a, > a:hover {
        &:after {
          transform: translateY(-50%) rotate(180deg);
        }
      }

      &.rd-navbar--has-megamenu {
        position: static;
      }
    }

    li.focus {
      > .rd-navbar-dropdown,
      > .rd-navbar-megamenu {
        opacity: 1;
        visibility: visible;
      }
    }
  }
}

// Default menu dropdowns
%rd-navbar-default-menu-dropdown {

  .rd-navbar-nav li {
    &.opened,
    &.focus {
      > .rd-navbar-dropdown,
      > .rd-navbar-megamenu {
        opacity: 1;
        visibility: visible;
      }
    }
  }

  // Dropdown and megamenu styles
  .rd-navbar-dropdown,
  .rd-navbar-megamenu {
    display: block;
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 20;
    width: 270px;
    margin-top: -4px;
    padding: 0;
    visibility: hidden;
    transition: .3s all ease;
    text-align: left;
    opacity: 0;
    background: $white;
    border-top: 4px solid $primary;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15);

    .rd-navbar-dropdown {
      left: 100%;
      top: -1px;
      margin: 0;
      z-index: 2;
    }

    .rd-navbar-dropdown .rd-navbar-dropdown {
      z-index: 3;
    }

    &.rd-navbar-open-left,
    &.rd-navbar-open-left .rd-navbar-dropdown {
      right: 100%;
      left: auto;
    }
  }

  .rd-navbar-nav > .rd-navbar-submenu > .rd-navbar-dropdown.rd-navbar-open-left {
    right: 0;
  }

  .rd-navbar-dropdown {
    top: calc(100% + 42px);
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 25px;
  }

  // Megamenu
  .rd-navbar-megamenu {
    display: table;
    table-layout: fixed;
    width: 98%;
    right: 10px;
    left: 10px;
    max-width: 1500px;
    overflow: hidden;
    margin-left: auto;
    margin-right: auto;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 35px;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15);
    > li {
      display: table-cell;
    }

    h6 {
      font-weight: 600;

      & + * {
        margin-top: 10px;
      }
    }
    @media (min-width: $screen-lg-min) {
      padding-left: 25px;
      padding-right: 25px;
    }
    @media (min-width: $screen-xl-min) {
      padding-left: 50px;
      padding-right: 50px;
    }
  }

  // DEBUG STYLE for megamenu
  //.rd-navbar-megamenu {
  //  opacity: 1!important;
  //  visibility: visible!important;
  //}

  // DEBUG STYLE for dropdown
  //.rd-navbar-nav > li.rd-navbar--has-dropdown > .rd-navbar-dropdown {
  //  opacity: 1 !important;
  //  visibility: visible !important;
  //}


  .rd-navbar-dropdown,
  .rd-navbar-megamenu {
    padding-top: 23px;
    li {
      position: relative;

      &:hover {

      }

      > a {
        @extend %rd-navbar-default-link-font;
        position: relative;
        display: inline-block;
        font-size: 14px;
        font-weight: 600;
        text-transform: none;
        padding: 7px 0 7px 21px;
        color: $primary;

        &:hover, &:focus {
          color: $gray-dark;
          background: transparent;
          
          span {
            text-decoration: underline;
          }

          &:before {
            color: $gray-dark;
            opacity: 1;
            @include transform(translateX(0));
          }
        }

        &, &:before {
          transition: .3s all ease;
        }

        &:before {
          content: '\e5cc';
          position: absolute;
          top: 12px;
          left: 0;
          font: 18px/18px "Material Icons";
          margin-top: -6px;
          pointer-events: none;
          opacity: 0;
          @include transform(translateX(-5px));
        }

        > span {
          vertical-align: middle;
        }
      }

      // if has dropdown add padding right near the arrow
      &.rd-navbar--has-dropdown a {
        padding-right:30px;
      }

      &:focus > a {
        color: $gray-dark;

        &:before {
          color: $gray-dark;
          opacity: 1;
          @include transform(translateX(0));
        }
      }
    }

    li {
      &.rd-navbar--has-dropdown .rd-navbar-submenu-toggle{
        cursor: pointer;
        position: absolute;
        top: 50%;
        right: 13px;
        font-size: 22px;
        line-height: 22px;
        transform: translateY(-50%);
        transition: .3s all ease;

        &:after{
          content: '\f105';
          font-family: 'FontAwesome';
        }
      }
    }
  }

  &.rd-navbar--is-stuck {
    .rd-navbar-dropdown {
      top: calc( 100% + 11px );
    }
    @media (min-width: $screen-lg-min) {
      .rd-navbar-dropdown {
        top: calc( 100% + 16px );
      }
    }
  }
}
