//
// RD Navbar default
// --------------------------------------------------

.rd-navbar-default {
  @extend %rd-navbar-transition;

  .search_list {

    @media (min-width: $screen-lg-min) {
      columns: 1;
      -moz-columns: 1;
      column-gap: 0;
      -moz-column-gap: 0;
    }
  }

  // Fixed layout
  &.rd-navbar-fixed {
    // Mobile search
    @extend %rd-default-mobile-search;
  }

  // Fullwidth & Static layout
  &.rd-navbar-fullwidth,
  &.rd-navbar-static {

    // If need transparent navbar, just use .rd-navbar-transparent class
    &:not(.rd-navbar-transparent) {
      background: $rd-navbar-default-background;
    }

    @extend %rd-navbar-default-menu-first-lvl;
    @extend %rd-navbar-default-menu-dropdown;
    @extend %rd-navbar-default-search;

    // Rd navbar inner
    .rd-navbar-inner {
      @include display-flex();
      @include align-items(center);
      padding-left: 15px;
      padding-right: 15px;
    }

    // Position of navbar aside right
    .rd-navbar-aside-right {
      @include display-flex();
      @include align-items(center);
      @include justify-content(center);
    }

    //change search toggle settings on desktop
    .rd-navbar-search-toggle {
      display: inline-block;
      height: 22px;
      margin-left: 15px;
      vertical-align: middle;
      position: relative;
      z-index: 8;

      span {
        width: 20px;
        height: 20px;
        line-height: 20px;
        font-size: 18px;
      }

       span:hover{
         color: $primary;
       }
    }

    //Search form poosition inside rd-navbar-aside-right
    .rd-search {

      .form-input {
        border-radius: 35px;
        box-shadow: 0 0 5px 0 rgba($primary,.3);
        border-bottom: 0;
        padding-left: 25px;
        padding-right: 50px;
      }
      .form-label {
        left: 25px;
        top: 22px;
      }
      .form-label,
      .form-input {
        color: $gray-light;
        font-size: 16px;
        font-weight: 400;
        font-style: normal;
      }
    }

    // is stuck
    &.rd-navbar--is-stuck {
      // dropdown position
      .rd-navbar-nav {
        padding-top: 10px;
        padding-bottom: 10px;
      }
      .rd-navbar-nav > li {
        padding-top: 1px;
      }
    }

    [data-x-mode="true"] & {
      .rd-navbar-nav {
        > li {
          > .rd-navbar-submenu-toggle {

            &:before {
              right: 5px;
            }
          }
          &.active,
          &:hover,
          &:focus,
          &.opened {
            > .rd-navbar-submenu-toggle {
            }
          }
        }
      }
    }
  }

  // Fullwidth layout
  &.rd-navbar-fullwidth {

    // Rd navbar inner
    .rd-navbar-inner {
      @include flex-direction(column);
    }

    .rd-navbar-nav-wrap {
      .rd-navbar-brand {
        display: none;
      }
    }

    

    .rd-navbar-dropdown {
      top: calc(100% + 22px);
    }

    // Define some sizes, but not in stuck
    &:not(.rd-navbar--is-stuck) {
      .rd-navbar-inner {
        padding-top: 20px;
        padding-bottom: 20px;
      }
      .rd-navbar-aside-right {
        margin-top: 20px;
      }
      .rd-navbar-megamenu {
        top: calc(100% + 22px);
      }
    }

    .rd-search {
      top: 2px;
    }
    //aside right
    .rd-navbar-aside-right {
      @include justify-content(center);
      width: 100%;
    }

    // search style
    .rd-navbar-search.active {
      .rd-navbar-search-toggle {
        position: absolute;
        top: 12px;
        right: 0;
      }
    }

    // is stuck
    &.rd-navbar--is-stuck {
      .rd-navbar-brand {
        display: none;
      }
      .rd-navbar-search.active {
        .rd-navbar-search-toggle {
          top: 14px;
        }
      }
    }
  }

  // Static layout
  &.rd-navbar-static {

    // Rd navbar inner
    .rd-navbar-inner {
      @include justify-content(center);
      position: relative;
      padding-top: 5px;
      padding-bottom: 5px;
    }

    // rd-navbar-aside-right
    .rd-navbar-aside-right {
      position: static;
    }

    .rd-search {
      max-width: 670px;
      width: 100%;
      left: auto;
      right: 0;
      top: 33px;
    }

    .rd-navbar-panel {
      display: none;
    }

    // Define some sizes, but not in stuck
    &:not(.rd-navbar--is-stuck) {
      .rd-navbar-inner {
        padding-top: 30px;
        padding-bottom: 30px;
      }
    }

    // is stuck
    &.rd-navbar--is-stuck{
      .rd-search {
        top: 8px;
      }
      .rd-navbar-nav-wrap .rd-navbar-brand {
        padding: 0;
        width: 0;
        height: 0;
        overflow: hidden;
      }
    }
  }
}