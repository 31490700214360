//
// Form styles
// --------------------------------------------------

.rd-mailform {
  position: relative;
  text-align: left;

  > * + * {
    margin-top: 10px;
  }
}

.rd-mailform-inline-flex {
  @media (min-width: $screen-sm-min) {
    @include display-flex;
    @include justify-content(space-between);
    @include align-items(flex-start);

    > .form-wrap + .form-wrap,
    > .form-wrap + .button {
      margin-left: 10px;
      margin-top: 0;
    }
    > .form-wrap {
      @include flex-grow(1);
    }
  }

  * + .rd-mailform-inline-flex {
    margin-top: 10px;
  }
}

.form-input {
  display: block;
  width: 100%;
  height: $input-height;
  padding: $input-padding-vertical $input-padding-hoizontal;
  font-size: $input-font-size;
  font-weight: $input-font-weight;
  line-height: $input-line-height;
  color: $input-color;
  background-color: $input-bg;
  background-image: none;
  border: 1px solid $input-border;
  border-radius: $input-border-radius;
  -webkit-appearance: none;
  transition: .3s ease-in-out;

  &:focus {
    outline: 0;
  }
}

.form-input__style-1 {
  max-width: 144px;
}


textarea.form-input {
  height: 180px;
  min-height: 150px;
  max-height: 250px;
  resize: vertical;
}

textarea.textarea-mod-1 {
  height: 128px;
  min-height: 120px;
  max-height: 180px;
}

// Form wrapper
// -------------------------

.form-wrap {
  position: relative;

  * + & {
    margin-top: 10px;
  }
}

.form-wrap + .form-wrap,
.form-wrap + .button {
  margin-top: 10px;
}

// Form labels
// -------------------------

.form-label {
  position: absolute;
  top: 25px;
  left: $input-padding-hoizontal;
  font-size: $input-font-size;
  font-weight: $input-font-weight;
  line-height: $input-line-height;
  color: $input-color-placeholder;
  pointer-events: none;
  z-index: 9;
  transition: .3s;
  transform: translateY(-50%);

  &.focus {
    opacity: 0;
  }

  &.auto-fill {
    color: $input-color;
  }

  &.form-label-primary {
    color: $primary;
  }
}

[data-x-mode="true"] .form-label {
  pointer-events: auto;
}

.form-label-outside {
  @media (min-width: $screen-sm-min) {
    position: static;
    &, &.focus, &.auto-fill {
      transform: none;
      color: $input-color-placeholder;
      font-size: $input-font-size;
    }
  }
}

// Form validation
// -------------------------

.form-validation {
  position: absolute;
  right: 10px;
  top: 2px;
  font-size: 11px;
  line-height: 11px;
  color: $error-color;
  transition: .3s;
  z-index: 11;
}

.form-validation-left {
  .form-validation {
    right: auto;
    top: 100%;
    left: 0;
  }
}

.form-validation-bottom {
  .form-validation {
    top: 101%;
  }
}

// Form output
// -------------------------

#form-output-global {
  position: fixed;
  bottom: 30px;
  left: 15px;
  visibility: hidden;
  transform: translateX(-500px);
  transition: .3s all ease;
  z-index: 9999999;

  &.active {
    transform: translateX(0);
    visibility: visible;
  }

  @media (min-width: $screen-xs) {
    left: 30px;
  }
}

.form-output {
  position: absolute;
  top: 100%;
  left: 0;
  font-size: 14px;
  line-height: 1.5;
  margin-top: 2px;
  transition: .3s;
  opacity: 0;
  visibility: hidden;

  &.active {
    opacity: 1;
    visibility: visible;
  }

  &.error {
    color: $error-color;
  }

  &.success {
    color: $success-color;
  }
}

// Radio and Checkbox Custom
// -------------------------

// Base Styles
//
.radio .radio-custom,
.radio-inline .radio-custom,
.checkbox .checkbox-custom,
.checkbox-inline .checkbox-custom {
  opacity: 0;

  &, &-dummy {
    position: absolute;
    width: 24px;
    height: 24px;
    margin-left: -35px;
    outline: none;
    cursor: pointer;
  }

  &-dummy {
    pointer-events: none;
    background: $gray-lightest;

    &:after {
      position: absolute;
      opacity: 0;
    }
  }

  &:focus {
    outline: none;
  }
}

.radio-custom:checked + .radio-custom-dummy:after,
.checkbox-custom:checked + .checkbox-custom-dummy:after {
  opacity: 1;
}

// Custom Radio Styles
//
.radio,
.radio-inline {
  padding-left: 36px;
  @media (min-width: $screen-md-min) {
    margin-left: -36px;
  }
  .radio-custom-dummy {
    border-radius: 50%;
    width: 18px;
    height: 18px;
    top: 8px;

    &:after {
      content: '';
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: $gray-darker;
      border-radius: 50%;
    }
  }
}

// Custom Checkbox Styles
//
.checkbox,
.checkbox-inline {
  padding-left: 36px;
  line-height: 25px;
  .checkbox-custom-dummy {
    pointer-events: none;
    border-radius: 3px;
    margin-left: 0;
    left: 0;

    &:after {
      content: '\e5ca';
      font-family: 'Material Icons';
      font-size: 20px;
      line-height: 10px;
      position: absolute;
      top: 50%;
      left: 50%;
      @include transform(translate(-50%, -50%));
      color: $primary;
    }
  }
}

//subscribe
.form-subscribe {
  text-align: center;
  @include align-items(stretch);

  .form-input {
    height: 66px;
  }

  @media (min-width: $screen-sm-min) {
    .form-input {
      font-size: 18px;
    }
  }
}


.rd-search {
  @media (min-width: $screen-xs-min) {
    @include display-flex;
    @include align-items(flex-end);
    .form-wrap {
      @include flex-grow(1);
    }
    .button {
      margin-left: 10%;
    }
  }
}

//search
//----------------------------------

.form-search.rd-search {
  position: relative;

  .form-input {
    padding-right: 45px;
  }

  .form-label {
    left: 20px;
    top: 25px;
    color: $gray-base;
    text-transform: none;
    font-size: 15px;
  }
}

.form-search-submit {
  position: absolute;
  font-size: 18px;
  right: 20px;
  top: 0;
  bottom: 0;
  padding: 0;
  background: none;
  border: none;
  box-shadow: none;
  color: $primary;

  .icon {
    font-size: 18px;
  }

  &:hover,
  &:focus {
    color: $gray-dark;
    outline: none;
    .icon {
      color: $gray-dark;
    }
  }
}

