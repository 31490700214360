@charset "UTF-8";
/**
* Template Style
*
* [Table of contents]
*   1 Custom styles
*     1.1 FontAwesome
*     1.2 Main Styles
*     1.3 Typography
*     1.4 Backgrounds
*     1.5 Colors
*     1.6 Icons
*     1.7 Buttons
*     1.8 Forms
*     1.9 Tables
*     1.10 Text-alignment
*     1.11 Text-styling
*     1.12 Visibility responsive
*     1.13 Groups
*     1.14 Context Styling
*     1.15 Flex grid
*     1.16 Unit-responsive
*     1.17 Sections
*     1.18 Offsets
*
*   2 Plugins
*     2.1 Animate
*     2.2 Isotope
*     2.3 Owl Carousel
*     2.4 RD-Navbar
*     2.5 RD-Parallax
*     2.6 RD-Google-Map
*     2.7 RD-Search
*     2.8 To-top
*     2.9 Tabs-and-accordions-custom
*     2.10 Tabs-and-accordions-custom
*     2.11 Photoswipe
*     2.12 Progress-bars
*     2.13 Counter
*     2.14 jquery-circle-progress
*     2.15 Timecircles
**/
// Import variables
@import "variables-custom";
// Import mixins
@import "mixins";
// Import fonts
@import "fonts/font-awesome";
@import "fonts/material-icons";
@import "fonts/mdi";

//
// Main Styles
// --------------------------------------------------

// Remove leading spacing of element
html {
  *:first-child {
    margin-top: 0;
  }

  *:last-child {
    margin-bottom: 0;
  }
}

// Body
body {
  font-family: $font-family-base;
  font-size: $font-size-base;
  line-height: $line-height-base;
  color: $text-color;
  background-color: $body-bg;
  font-weight: $font-weight-base;
}

section {
  padding-top: 1px;
  padding-bottom: 1px;
  margin-top: -1px;
}

// Links
a {
  color: $link-color;
  text-decoration: none;

  &:hover,
  &:focus {
    text-decoration: $link-hover-decoration;
    outline: none;
  }
  &:focus {
    color: $gray-base;
  }
  &:hover {
    color: $link-hover-color;
  }
}

.p a:hover,
.list a:hover {
  text-decoration: underline;
}

p a[href^='callto:'] {
  white-space: nowrap;
}

// Images
img {
  display: inline-block;
  max-width: 100%;
  height: auto;
}

.img-fullwidth {
  width: 100%;
}

svg {
  fill: currentColor;
}

//time 
time {
  &.big {
    color: $gray-dark;
  }
}

//
// Typography
// --------------------------------------------------

#{headings()} {
  margin-top: 0;
  margin-bottom: 0;
  font-weight: $headings-font-weight;
  color: $headings-color;
}

h1,
.heading-1 {
  font-size: 36px;
  line-height: 1.5;
  font-family: $font-family-sec;

  @media (min-width: $screen-sm-min) {
    line-height: $font-lh-h1;
    font-size: $font-size-h1;
  }
}

h2,
.heading-2 {
  font-size: 32px;
  line-height: 1.5;
  font-family: $font-family-sec;

  @media (min-width: $screen-sm-min) {
    line-height: $font-lh-h2;
    font-size: $font-size-h2;
  }
}

h3,
.heading-3 {
  font-size: 28px;
  line-height: 1.5;
  font-weight: 600;

  @media (min-width: $screen-sm-min) {
    line-height: $font-lh-h3;
    font-size: $font-size-h3;
  }
}

h4,
.heading-4 {
  font-size: 21px;
  line-height: 1.5;
  letter-spacing: .15em;
  color: $gray-base;
  text-transform: uppercase;

  @media (min-width: $screen-sm-min) {
    line-height: $font-lh-h4;
    font-size: $font-size-h4;
  }
}

h5,
.heading-5 {
  font-size: 20px;
  line-height: 1.5;

  @media (min-width: $screen-sm-min) {
    line-height: $font-lh-h5;
    font-size: $font-size-h5;
  }
}

.heading-5 {
  span {
    color: $gray-base;
  }
}

h6,
.heading-6 {
  font-size: 17px;
  line-height: 1.5;
  font-weight: 700;

  span {
    color: $gray-base;
  }

  @media (min-width: $screen-sm-min) {
    line-height: $font-lh-h6;
    font-size: $font-size-h6;
  }
}

.subtitle {
  font-size: 17px;
  line-height: 26px;
  font-weight: 400;
}

.heading-styling {
  h5,
  .heading-5,
  h6,
  .heading-6 {
    font-weight: 600;
  }
}

.text-styling-mod {
  font-weight: 600;
}

// Link in headings
#{headings()} {
  a {
    transition: .3s all ease;
  }

  a:active {
    color: $gray-base;
  }

  a:hover {
    color: $gray-dark;
  }

  .link {
    display: inline;
  }
}

// Big text
.big {
  font-size: $font-size-large;
  line-height: 28px;
}

mark {
  background-color: $primary;
  color: $white;
}

// Small text
small,
.small {
  display: block;
  font-size: $font-size-small;
}

// Divider
.divider {
  margin-bottom: 0;
  border-top: 1px solid $primary;
}

// Lists
// -------------------------

ul,
ol {
  list-style: none;
  padding: 0;
  margin: 0;
}

.list {
  text-align: left;
  li {
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .active {
    color: $gray-dark;
  }
}

.list-xl {
  text-align: left;

  li {
    padding-top: 15px;
    padding-bottom: 15px;
  }
  @media (min-width: $screen-lg-min) {
    li {
      padding-top: 25px;
      padding-bottom: 25px;
    }
  }
}

.list-condensed {
  li {
    padding-top: 1px;
    padding-bottom: 1px;
  }
}

.list-centered {
  text-align: center;
}

// List inline
//

@mixin list-inline($value, $value1 : $value) {
  margin-left: -$value1;
  margin-right: -$value;

  > li {
    padding-left: $value1;
    padding-right: $value;
  }
}

.list-inline {
  margin-left: -5px;
  margin-right: -5px;

  > li {
    padding-left: 5px;
    padding-right: 5px;
  }
}

.list-inline-md {
  @include list-inline(26px, 26px);
}

// Description Lists
//

dl {
  margin: 0;
}

// List terms
//

.list-terms {
  margin-top: 58px;
  dt + dd {
    margin-top: 17px;
  }
  dd + dt {
    margin-top: 45px;
  }
  dd {
    line-height: inherit;
  }
}

// List index
//
.list-index {
  text-align: left;
  counter-reset: li;

  li {
    position: relative;
    padding: 4px 0 5px 30px;

    &:before {
      position: absolute;
      top: 0;
      left: 0;
      content: counter(li, decimal-leading-zero) '.';
      counter-increment: li;
      color: $gray-base;
    }
  }
}

.list-index-small {
  li {
    &:before {
      top: 4px;
    }
  }
}

// List index uppercase
//
.list-index-uppercase {
  text-align: left;
  counter-reset: li;

  li {
    font-weight: 600;
    text-transform: uppercase;
    color: $primary;
    position: relative;
    padding: 8px 0 9px 42px;

    &:before {
      position: absolute;
      top: -4px;
      left: 0;
      content: counter(li, decimal-leading-zero) '.';
      counter-increment: li;
      color: $gray-dark;
      font-size: 24px;
    }
  }
}

//range index
.range-index {
  text-align: left;
  counter-reset: div;

  div {
    .index-counter:before {
      content: counter(div, decimal-leading-zero) '.';
      counter-increment: div;
      color: $gray-base;
    }
  }
}

// List marked
//

.list-marked {
  text-align: left;
  li {
    position: relative;
    padding: 4px 0 5px 17px;

    &:before {
      content: '\e5cc';
      font-family: "Material Icons";
      font-size: 18px;
      font-weight: 400;
      display: inline-block;
      color: $primary;
      position: absolute;
      left: -4px;
      top: 2px;
    }
    &.active {
      color: $gray-dark;
    }
  }

  &-gray {
    li {
      font-weight: 600;
      &:before {
        color: $gray-base;
      }
    }
  }
}

.list-marked-big {
  li {
    font-size: 18px;
    font-weight: 600;
    &:before {
      color: $gray-base;
      font-size: 24px;
      top: -1px;
    }
  }
}

.list-marked__style-1 {
  li:before {
    content: none;
  }
  li {
    padding: 4px 0 5px 0;
  }
  li .link {
    position: relative;
    padding-left: 20px;
    &:before {
      content: '\e5cc';
      font-family: "Material Icons";
      font-size: 18px;
      font-weight: 400;
      display: inline-block;
      position: absolute;
      left: -10px;
      top: -2px;
      opacity: 0;
      transition: 250ms;
    }
    &:hover {
      span {
        text-decoration: underline;
      }
      &:before {
        opacity: 1;
        left: -4px;
      }
    }
  }
}

.list-marked-primary-big {
  li {
    font-size: 18px;
    &:before {
      top: 5px;
    }
  }
}

// list 2 columns

.list-2-col {
  columns: 2;
  -moz-columns: 2;
  column-gap: 30px;
  -moz-column-gap: 30px;
  li {
    page-break-inside: avoid;
    break-inside: avoid;
  }
}

//
// Backgrounds
// --------------------------------------------------
// bg-behaviour() off padding top if two section with same bg that are beside

.bg-white {
  background: $white;
  @include bg-behaviour();
}

.bg-gray-lightest {
  background: $gray-lightest;
  @include bg-behaviour();

  .form-input {
    background-color: $white;
    border-color: $white;
  }
}

.bg-primary {
  background: $primary;
  @include bg-behaviour();

  *,
  .quote-variant-1 * {
    color: $white;
  }
}

[class*='bg-image'] {
  background-size: cover;
  background-position: center center;
  position: relative;

  > * {
    position: relative;
  }
  
  .icon {
    color: $white;
  }
}

.bg-image-1 {
  background-image: url('../images/bg-image-1.jpg');
}

.bg-image-2 {
  background-image: url('../images/bg-image-2.jpg');
}

.bg-image-3 {
  background-image: url('../images/bg-image-3.jpg');
}

.bg-image-4 {
  background-image: url('../images/bg-image-4.jpg');
}

.bg-image-5 {
  background-image: url('../images/bg-image-5.jpg');
}

.bg-image-6 {
  background-image: url('../images/bg-image-6.jpg');
}

.bg-image-7 {
  background-image: url('../images/landing-8-1920x417.jpg');
}

.bg-image-8 {
  background-image: url('../images/landing-11-1920x410.jpg');
}

.bg-image-progress {
  background-image: url('../images/bg-progress.jpg');
}

.bg-image-progress-2 {
  background-image: url('../images/landing-6-1920x212.jpg');
}

.bg-blog {
  background-image: url('../images/bg-blog.jpg');
  h3,
  h4 {
    color: $white;
  }
}

//
// Colors
// --------------------------------------------------
.page {
  @include text-variant('.text-gray-base', $gray-base, $primary);
  @include text-variant('.text-gray-darker', $gray-darker, $primary);
  @include text-variant('.text-gray-dark', $gray-dark, $primary);
  @include text-variant('.text-gray', $gray, $primary);
  @include text-variant('.text-gray-light', $gray-light, $primary);
  @include text-variant('.text-gray-lighter', $gray-lighter, $primary);
  @include text-variant('.text-primary', $primary, darken($primary, 10%));
  @include text-variant('.text-white', $white, darken($white, 10%));
}

//
// Components
// --------------------------------------------------
@import "components/icons";
@import "components/buttons";
@import "components/forms";
//@import "components/tables";
@import "components/links";
@import "components/quote";
//@import "components/breadcrumbs-custom";
//@import "components/thumbnail-custom";
@import "components/product";
//@import "components/pagination-custom";
//@import "components/jquery.fs.stepper";
@import "components/post";
@import "components/banner-box";

//
// Helpers
// --------------------------------------------------
@import "helpers/text-alignment";
@import "helpers/text-styling";
@import "helpers/visibility-responsive";
@import "helpers/groups";
@import "helpers/context-styling";
@import "helpers/utilities-custom";

//
// Modules
// --------------------------------------------------
@import "modules/flex-grid";
@import "modules/unit-responsive";

//
// Others
// --------------------------------------------------
@import "others/snackbars";
@import "others/sections";
@import "others/offsets"; 

//
// Main layout
// --------------------------------------------------

// Page
.page {
  overflow: hidden;
  //min-height: 100vh !important;
}

// Page header
.page-header {
  //off bootstrap style
  padding: 0;
  margin: 0;
  border-bottom: none;
  // Custom style
}

// Page footer
.page-footer {
  background-color: $gray-lightest;
  h6 + * {
    margin-top: 17px;
  }

  * + .unit {
    margin-top: 12px;
  }
  .brand {
    @media (min-width: $screen-lg-min) {
      display: inline-block;
      margin-top: -20px;
    }
  }
}

.page-footer-minimal {
  background-color: transparent;
}

.copyright {
  background-color: $gray;
  padding-top: 12px;
  padding-bottom: 12px;
  * {
    color: $white;
  }
}

.copyright-minimal {
  padding-top: 22px;
  padding-bottom: 25px;
}

.copyright-sidebar {
  color: $primary;
}

//
// Plugins
// --------------------------------------------------

@import "plugins/animate";
//@import "plugins/isotope";
@import "plugins/owl-carousel";
@import "plugins/rd-navbar";
//@import "plugins/rd-parallax";
@import "plugins/rd-google-map";
//@import "plugins/rd-search";
//@import "plugins/swiper";
@import "plugins/ui-to-top";
//@import "plugins/tabs-and-accordions-custom";
//@import "plugins/tabs";
@import "plugins/photoswipe";
//@import "plugins/progress-bars";
//@import "plugins/counter";
//@import "plugins/jquery-circle-progress";
//@import "plugins/timecircles";
//@import "plugins/select2";
//@import "plugins/slick";

 
.flickr {
  margin-left: -4px;
  margin-bottom: -5px;
  @include transform(translateY(-5px));

  .flickr-item {
    display: inline-block;
    width: 84px;
    height: 83px;
    margin-left: 4px;
    margin-top: 5px;
    overflow: hidden;
  }
  img {
    width: 100%;
  }
  .flickr-item-mod-1 img {
    width: auto;
  }
  @media (min-width: 2500px) {
    .flickr-item-mod-1 img {
      width: 160%;
    }
  }
}

//comment box
.comment-wrap {
  textarea.form-input {
    height: 110px;
    min-height: 110px;
    max-height: 150px;
  }
}

.comment-box {

  & + .comment-box {
    margin-top: 20px;
    border-top: 1px solid $gray-lightest;
    padding-top: 20px;
  }

  & + .rd-mailform {
    margin-top: 20px;
  }
}

//gmap-box-wrap
.gmap-box-wrap {

  p {
    margin-bottom: 0;
  }

  * + .big {
    margin-top: 5px;
  }

  h5 + .big {
    margin-top: 18px;
  }

  p + h5 {
    margin-top: 45px;
  }

  .google-map {
    height: 240px;

    * + & {
      margin-top: 20px;
    }
  }
}

//twiter
.twitter-sm {
  * + & {
    margin-top: 15px;
  }
}

//img wrap
.img-wrap-1 {
  @media (min-width: $screen-lg-min) {
    margin-top: -30px !important;
  }
}

.img-wrap-2 {
  @media (min-width: $screen-xl-min) {
    max-width: 205px;
    img {
      float: right;
      width: auto;
      max-width: none;
    }
  }
}

@media (min-width: $screen-sm-min) {
  .bg-gradient {
    position: relative;

    &:before {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 50%;
      content: "";
      display: inline-block;
      background: linear-gradient(to top, #e9eef0, rgba($white, 0));
    }
  }
}

.bg-cover {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

//header transparent
//------------------------------------------

.header-transparent {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

//landing

.page-img-wrap {
  position: relative;
  height: 393px;
  max-width: 270px;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
  box-shadow: $shadow-area-sm;

  img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    transition: 2s linear;
  }

  &:hover {
    img {
      transform: translateY(calc(-100% + 393px));
    }
  }

  .ie-10 &,
  .ie-11 &,
  .ie-edge & {
    &:hover {
      img {
        top: 393px;
        transform: translateY(-100%);
      }
    }
  }
}

//contact info
.contact-info {
  * + .unit {
    margin-top: 10px;
  }
}