@mixin responsive-offset($offset) {
  position: relative;
  transform: translateY(-$offset);
  margin-bottom: -$offset;
  pointer-events: none;

  &:after,
  &:before,
  > *:before {
    content: '';
    display: table;
    width: 0;
  }

  > * {
    margin-top: $offset;
    pointer-events: auto;
  }
}

@mixin responsive-offset-media($offset) {
  transform: translateY(-$offset);
  margin-bottom: -$offset;


  > * {
    margin-top: $offset;
  }
}