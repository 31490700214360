//
// Sections
// --------------------------------------------------

// Section Spacing
// -------------------------
.section-xs{
  padding-top: 44px;
  padding-bottom: 44px;
}
.section-sm {
  padding-top: 60px;
  padding-bottom: 60px;
}

.section-md {
  padding-top: 80px;
  padding-bottom: 85px;
}

.section-md-top {
  padding-top: 80px;
  padding-bottom: 45px;
}

.section-md-bottom {
  padding-top: 80px;
  padding-bottom: 0;
}

.section-md-footer {
  padding-top: 80px;
  padding-bottom: 55px;
}

.section-lg {
  padding-top: 40px;
  padding-bottom: 50px;
  @media (min-width: $screen-sm-min) and (min-height: 700px) {
    padding-top: 115px;
    padding-bottom: 135px;
  }
}

.section-xxl { 
  padding-top: 80px;
  padding-bottom: 80px;
  @media (min-width: $screen-sm-min)  {
    padding-top: 120px;
    padding-bottom: 120px;
  }
  @media (min-width: $screen-lg-min) {
    padding-top: 150px;
    padding-bottom: 150px;
  }
} 




// Section Styles
// -------------------------

.section-relative {
  position: relative;
  z-index: 1;
}

// Section Grid Demonstration
.section-grid-demonstration {
  @media (max-width: $screen-xs-min) {
    [class^="col"] {
      padding: 5px;
    }
  }
  .grid-demonstration-item {
    background: $gray-lighter;

    @media (min-width: $screen-sm-min) {
      padding: 35px 30px;
      text-align: left;
    }

    @media (max-width: $screen-md-max) {
      h3 {
        font-size: 16px;
        line-height: 16px;
      }
      p {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;
      }
    }
  }

  .divider + .row {
    margin-top: 35px;
  }
}