//
// RD Navbar default
// --------------------------------------------------

.rd-navbar-default-with-top-panel {
  @extend %rd-navbar-transition;

  // General styles for all type navbar
  .contact-info{
    text-align: left;
    font-size: 14px;

      .unit-horizontal {
        font-weight: 400;
      }
    .unit-left{
      color: $gray-base;
    }

  }

  // Fixed layout
  &.rd-navbar-fixed {

    // Mobile brand
    .rd-navbar-mobile-brand {
      display: inline-block;
      margin: 20px 0;
    }

    .rd-navbar-brand{
      display: none;
    }

    //off mobile brand and show standart brand
    @media (min-width: $screen-xs-min) {
      .rd-navbar-mobile-brand {
        display: none;
      }

      .rd-navbar-brand{
        display: inline-block;
      }
    }

    .rd-navbar-search-toggle{
      right: 44px;
    }
  }


  // Fullwidth & Static layout
  &.rd-navbar-fullwidth,
  &.rd-navbar-static {

    // Navbar top panel
    .rd-navbar-top-panel{
      background-color: $gray-lighten;
    }



    .rd-navbar-inner {
      max-width: 1200px;
    }

    // is stuck
    &.rd-navbar--is-stuck {
      //hide collapse
      .rd-navbar-collapse-toggle,
      .rd-navbar-top-panel{
        display: none;
      }
    }

  }


  // Fullwidth layout
  &.rd-navbar-fullwidth {

    // is stuck
    &.rd-navbar--is-stuck {
      .rd-navbar-brand {
        display: none;
      }
    }
  }


  // Static layout
  &.rd-navbar-static {

    .rd-navbar-top-panel-inner{
      @include display-flex();
      @include align-items(stretch);
      @include justify-content(space-between);
      padding-left: 15px;
      padding-right: 15px;
      height: 46px;
      max-width: 1200px;
    }

    .rd-navbar-top-panel-inner {
      > * {
        @include display-flex();
        @include align-items(center);
      }
    }

    .right-side {
      .list-inline {
        letter-spacing: -4px;
        height: 100%;
        @include display-flex();
      }
      .list-inline li {
        border-left: 1px solid $gray;
        letter-spacing: 0;
        height: 100%;
        @include display-flex();
        @include align-items(center);

        &:last-of-type {
          border-right: 1px solid $gray;
        }
      }
    }
  }

}