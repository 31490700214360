// Product
//----------------------------------------------

.product-wrap{

}

//product list
.list-products {
  text-align: left;
  max-width: 225px;
  margin-left:auto;
  margin-right:auto;

  > li {
    position: relative;
    display: inline-block;
    padding:16px 25px 16px 0;
    font-size: 24px;

    &:before {
      position: absolute;
      top: 18px;
      right: 2px;
      content: '\e5cf';
      display: inline-block;
      font-size: 24px;
      font-family: "Material Icons";
      color: $primary;
      transition: 250ms ease-in;
      opacity: 1;
    }

    &.active {
      &:before {
        opacity: 0;
      }
    }
    &:hover{
     &:before{
       color: $gray-dark;
     }
    }

    & + li {
      border-top: 1px solid $gray-lightest;
    }

    @media (min-width: $screen-md-min) {
      display: block;
      margin-left: 0;
    }
  }

  .list-marked {
    > li {
      padding: 2px 0 2px 17px;
      font-size: 18px;
      &:before{
        top: 5px;
      }
    }
    > li.active {
      &:before{
        color: $gray-dark;
      }
    }
  }

  li.active > a {
    color: $gray-dark;
  }
}

//product
.product {
  position: relative;
  max-width: 270px;
  margin-left:auto;
  margin-right:auto;
  padding: 20px 15px 40px;
  min-height: 490px;
  background-color: $gray-lightest;
  vertical-align: top;

  * + p {
    margin-top: 20px;
  }

  p + p {
    margin-top: 13px;
  }

  * + .button {
    margin-top: 10px;
  }

  &:before{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    content: "";
    display: inline-block;
    @include transform(scale(1.02));
    border: 1px solid $primary;
    opacity: 0;
    transition: 350ms ease-in;
    pointer-events: none;
  }
  &:hover{
    &:before{
      opacity: 1;
      @include transform(scale(1));
    }
  }
}

.product-caption {
  position: relative;
  margin-top: 15px;

  h6 {
    font-weight: 400;
  }
}


.product-price,
.product-rating .link,
.product-number,
.product-description .button {
  font-weight: 600;
  font-size: 18px;
}

.product-rating .link {
  color: $primary;  
}

.checkout-box h6 {
  font-weight: 600;
}

//product-description
.product-description {
  #{headings()} {
    font-weight: 600;
  }

  > * + *,
  > * + .button {
    margin-top: 18px;
  }

  * + p {
    margin-top: 20px;
  }

  h5 + .big {
    margin-top: 20px;
    @media (min-width: $screen-md-min) {
      margin-top: 43px;
    }
  }

  * + .product-rating {
    margin-top: 25px;
    @media (min-width: $screen-md-min) {
      margin-top: 42px;
    }
  }

  .button-sm {
    font-size: 14px;
  }
}

//product price
.product-price {
  color: $gray-dark;
}

.product-price-single {
  font-size: 24px;
}

.product-price-old {
  color: $gray-base;
}


//product rating
.product-rating {
  .icon + .icon {
    margin-left: 4px;
  }
}

//product-number
.product-number {
  > * {
    vertical-align: middle;
  }
}

//payment box
.payment-box {
  padding: 20px 15px;
  border: 1px solid $gray-lightest;
  text-align: left;

  @media (min-width: $screen-md-min) {
    padding:42px 95px;
  }
  .radio-inline {
    font-size: 24px;
    line-height: 1.2;
    vertical-align: bottom;
    color: $gray-dark;
  }
  .link {
    padding-top: 5px;
    margin-left: 10px;
    font-size: 18px;
    text-decoration: underline;
  }
  * + .group-xs {
    margin-top: 20px;
  }

  & + .button {
    margin-top: 15px;
  }
}