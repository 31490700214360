//
// RD Navbar
// --------------------------------------------------

// Variables
$rd-navbar-default-background:    $white;

// Rd-navbar inner width
$rd-navbar-width: 1200px;

// Fixed Navbar Colors
$rd-navbar-fixed-bg:              $white;
$rd-navbar-fixed-toggle-color:    $primary;

//RD Navbar togles
$rd-navbar-toggle-preset:         "button-lines-arrow-2";
$rd-navbar-collapse-toggle-preset: "collapse-preset-1";


// import mixins
@import "rd-navbar_includes/_rd-navbar-mixins.scss";

.rd-navbar {
  display: none;
  position: relative;
  z-index: 30;
}


// Default link font
%rd-navbar-default-link-font {
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
}

// RD Navbar Basics
%rd-navbar-transition {
  transition: .3s all cubic-bezier(0.785, 0.135, 0.150, 0.860);
}

.rd-navbar a {
  transition: .3s ease-in-out;
}

.rd-navbar--no-transition {
  &, * {
    transition: none !important;
  }
}

.rd-navbar-fixed,
.rd-navbar-fullwidth,
.rd-navbar-static {
  display: block;
}

[data-x-mode="true"] {
  .rd-navbar-fullwidth,
  .rd-navbar-static {
    .rd-navbar-nav {
      > li {
        > .rd-navbar-submenu-toggle {
          position: relative;
          font-size: 10px;

          &:before {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            content: "\f078";
            font-family: "FontAwesome";
            color: #ccc;
          }
        }
      }
    }
  }
}

.rd-navbar-fullwidth.rd-navbar--is-stuck,
.rd-navbar-static.rd-navbar--is-stuck {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding-left: 15px;
  padding-right: 15px;
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.15);
}

.rd-navbar.rd-navbar--is-clone {
  display: none!important;
}



// Include components and placeholders
@import "rd-navbar_includes/rd-navbar-components";

// Include general style for all navbar types
@import "rd-navbar_includes/rd-navbar-fixed";


// Include specific navbars
@import "rd-navbar-default";
@import "rd-navbar-default-with-top-panel";
@import "rd-navbar-transparent";
@import "rd-navbar-sidebar";




