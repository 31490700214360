//Post
//---------------------------------

.post {
  * + .caption {
    margin-top: 26px;
  }
  * + h6 {
    margin-top: 0;
  }
  p + ul {
    margin-top: 20px;
  }
  time {
    font-weight: 600;
  }
  h6 {
    font-weight: 600;
  }

  * + & {
    margin-top: 60px;
  }
  img {
    width: 100%;
  }
  
}

.post-author {
  color: $primary;
  display: table-caption;
  text-align: left;
  @media (min-width: 768px) {
    text-align: center;
  }
}

.post-aside {
  text-align: left;

  & + & {
    margin-top: 5px;
  }
  
  time {
    display: block;
  }

  .post-title {
    font-weight: 600;
  }
}

.post-aside__style-1 {
  .post-title {
    font-size: 18px;
  }
}



.single-post-title,
.time-post-title {
  font-weight: 600;

  & + p {
    margin-top: 15px;
  }
}

.time-post-title {
  font-size: 18px;
}

//tags-list
.tags-list {
  margin-left: -3px;
  margin-right: -3px;

  li {
    display: inline-block;
    padding: 3px;
  }
}

.tags-list-links {
  margin-left: -10px;
  margin-right: -10px;

  li {
    position: relative;
    display: inline-block;
    padding: 2px 10px;

    > a {
      font-weight: 600;
    }

    & + li {
      &:before {
        position: absolute;
        left: 0;
        top: 5px;
        bottom: 3px;
        content: "";
        display: inline-block;
        border-left: 2px solid $primary;
      }
    }
  }

  * + & {
    margin-top: 23px;
  }
}

//pagination-post
.pagination-post {
  text-align: center;
}

//post meta list

.post-meta-list {
  margin-left: -10px;
  margin-right: -10px;
  margin-bottom: -6px;
  transform: translateY(-6px);

  li {
    display: inline-block;
    padding: 3px 10px;
  }

  & + * {
    margin-top: 15px;
  }
}

//comment box
.comment-box-inner,
.comment-box-inner-reply {
  padding: 15px;
  border: 1px solid #eee;
  text-align: left;
  margin-bottom: 5px;

  img {
    width: 50px;
    height: 50px;
  }

  @media (min-width: $screen-sm-min) {
    padding: 25px 20px;

    img {
      width: 70px;
      height: 70px;
    }
  }

  .post-meta-list {
    .unit-body {
      font-weight: 600;
    }
  }
}

.comment-author,
.icon {
  color: $primary;
}

@media (min-width: $screen-sm-min) {
  .comment-box-inner-reply {
    margin-left: 30px;
  }
}

//post time
%post-time {
  @media (min-width: $screen-sm-min) {
    padding-right: 15px;
  }

  time{
    display: block;
    padding: 10px 6px;
    max-width: 70px;
    margin-left: auto;
    margin-right: auto;
    min-width: 70px;
    background-color: $gray-1;
    text-align: center;
    span{
      display: block;
      font-weight: 600;
      line-height: 1;
      color: $gray-dark;

      &:first-of-type {
        font-size: 36px;
      }
      &:last-of-type {
        font-size: 18px;
      }
    }
  }

  .button {
    @media (min-width: $screen-lg-min) {
      margin-left: 74px;
    }
  }


}
.post-time-box {
  @extend %post-time;

  * + & {
    margin-top: 50px;
  }
}

.post-time-box-image {
  @extend %post-time;
  background-color: $white;
  padding-top: 30px;
  
  .unit-left {
    img {
      max-width: 80%;
    }    
  }


  time {
    display: inline-block;
  }

  .unit-vertical {
    padding:0 15px 30px;
  }

  * + .button-link {
    margin-top: 5px;
  }

  @media (min-width: $screen-sm-min) {
    padding-top: 0;
    text-align: left;

    .unit-left {
      img {
        max-width: 100%;
      }
    }

    .unit-vertical {
      padding: 30px 0 0;
    }
  }
}

//event post
.event-post {
  border: 1px solid $gray-lightest;
  text-align: center;

  img{
    width: 100%;
  }

  .button-link {
    font-weight: 600;
    text-transform: uppercase;
     &:after{
       content: none;
     }
  }

  * + .button-link,
  * + h6 {
    margin-top: 5px;
  }
}

.event-header {
  padding: 18px 15px;
  font-weight: 600;
}

.event-body {
  padding: 20px 20px 50px;
}

.event-post-variant-1 {
  .event-header {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 0;
    padding-right: 0;

    @media (min-width: $screen-sm-min) {
      justify-content: space-between;
    }

    time + h5 {
      margin-top: 0;
    }

  }
  .event-body {
    padding: 0;
  }

  .event-button {
    display: none;
  }

  @media (min-width: $screen-lg-min) {
    position: relative;
    padding-bottom: 170px;
    .event-wrap {
      position: absolute;
      bottom:0;
      left: 0;
      right: 0;
      background-color: $white;
    }
    .event-button {
      display: block;
    }

    .button {
      visibility: hidden;
      opacity: 0;
      margin-bottom: -90px;
    }

    .event-wrap {
      &.active {
        .button {
          visibility: visible;
          opacity: 1;
          margin-bottom: 0;
        }
        .event-button {
          background-color: $gray-lightest;
          .icon {
            color: $primary;
            transform: rotate(180deg);
          }
        }
      }
    }
  }
}
.event-img {
  img{
    width: 100%;
  }
}

.event-button {
  width: 40px;
  height: 40px;
  line-height: 38px;
  padding: 5px;
  background-color: $primary;
  border: 0;

  .icon {
    color: $white;
  }

  span:before{
    content: "\f236";
    display: inline-block;
  }
  &:focus {
    outline: none;
  }

  &:hover{
    background-color: $gray-lightest;
   .icon {
     color: $primary;
     transform: rotate(180deg);
   }
  }
}