//
// Offsets
// --------------------------------------------------
.page {
  .shell-no-gutter {
    padding-left: 0;
    padding-right: 0;
  }

  .shell-custom-gutter {
    @media (min-width: $screen-xl-min) {
      padding-left: 55px;
      padding-right: 55px;
    }
  }
}

// Element offset
#{headings()} {
  * + & {
    margin-top: 20px;
  }
}

* + p {
  margin-top: 15px;
}

* + article {
  margin-top: 30px;
}

* + .button {
  margin-top: 20px;
  @media (min-width: $screen-md-min) {
    margin-top: 45px;
  }
}

* + .big {
  margin-top: 27px;
}

* + .subtitle {
  margin-top: 17px;
}

* + hr {
  margin-top: 8px;
}

* + .list-inline {
  margin-top: 10px;
}

* + .form-subscribe {
  margin-top: 10px;
}

* + .banner-subtitle {
  margin-top: 25px;
}

* + .quote-classic-big {
  margin-top: 45px;
}

* + .pagination-classic {
  margin-top: 35px;

  @media (min-width: $screen-md-min) {
    margin-top: 55px;
  }
}

* + .carousel-parent {
  margin-top: 50px;
}

hr + * {
  margin-top: 25px;
}

.list-terms + * {
  margin-top: 30px;
}

.event-post-wrap + * {
  margin-top: 25px;
}

p + * {
  margin-top: 17px;
}

h1 + p,
h2 + p,
h3 + p,
h3 + .big{
  margin-top: 35px;
}

h5 + *,
h6 + * {
  margin-top: 18px;
}

h3 + * {
  margin-top: 25px;
  @media (min-width: $screen-md-min) {
    margin-top: 50px;
  }
}

.shell + h5 {
  margin-top: 50px;
}

h5 + .range {
  margin-top: 20px;
}

h1 + .DateCountdown {
  margin-top: 30px;
}

h1 + .panel-custom-group-wrap {
  margin-top: 40px;
}

h3 + .heading-5 {
  margin-top: 40px;
}

p + .list {
  margin-top: 12px;
}

h3 + h4 {
  margin-top: 10px;
}

h3 + h5 {
  margin-top: 25px;
}

h6 + h6 {
  margin-top: 7px;
}

h1 + h5 {
  margin-top: -5px;
}

h6 + .list {
  margin-top: 14px;
}

h3 + .rd-search {
  margin-top: 35px;
}

h4 + .owl-carousel {
  margin-top: 35px;
}

h5.index-counter + p {
  @media (min-width: $screen-md-min) {
    margin-top: 47px;
  }
}

img + .big {
  @media (min-width: $screen-sm-min) {
    margin-top: 45px;
  }
}

.big + img {
  margin-top: 35px;
}

p + p {
  margin-top: 21px;
}

@media (min-width: $screen-sm-min) {
  [class*="cell-"] {
    > p.big:first-child {
      margin-top: -8px;
    }
  }
}

html {
  @media (min-width: $screen-sm-min) {
    .offset-sm-top-80 {
      margin-top: 80px !important;
    }
  }

  // Range spacing
  .range-55 {
    @include responsive-offset(55px);
  }

  .range-50 {
    @include responsive-offset(50px);
  }

  .range-45 {
    @include responsive-offset(45px);
  }

  .range-30 {
    @include responsive-offset(30px);
  }

  .range-25 {
    @include responsive-offset(25px);
  }

  .range-20 {
    @include responsive-offset(20px);
  }

  .range-5 {
    @include responsive-offset(5px);
  }

  @media (min-width: $screen-md-min) {
    .range-md-75 {
      @include responsive-offset(75px);
    }
    .range-md-20 {
      @include responsive-offset(20px);
    }
  }
}

