@keyframes out {
  from {left: 0;  right: 0;}
  to  {left: 100%; right: 0;}
}

@keyframes in {
  from {right: 100%}
  to  {right: 0}
}



.rd-navbar-transparent {

  &.rd-navbar-fixed,
  &.rd-navbar-fullwidth {
    .rd-navbar-aside-right {
      position: fixed;
      top: 55px;
      right: 5px;
      padding: 25px 15px;
      opacity: 0;
      visibility: hidden;
      border-top: 4px solid $primary;
      transform: translateY(-20px);
      transition: 270ms ease-in-out;
      background-color: $white;
      box-shadow: $shadow-area-xs;

      &.active {
        opacity: 1;
        visibility: visible;
        transform: translateY(0);
      }
    }

    .rd-navbar-collapse-toggle {
      z-index: 9999;
    }
  }

  &.rd-navbar-fullwidth {
    .rd-navbar-collapse-toggle {
      top: 8px;
      span {
        &,
        &:before,
        &:after {
          background: $white;
        }
      }
    }
    
    .rd-navbar-dropdown {
      top: calc(100% + 42px);
    }

    &.rd-navbar--is-stuck {
      .rd-navbar-nav {
        padding-top: 20px;
        padding-bottom: 20px;
      }
      .rd-navbar-dropdown {
        top: calc(100% + 20px);
      }
    }
  }

  &.rd-navbar-fullwidth,
  &.rd-navbar-static {

    .rd-navbar-panel {
      display: none;
    }

    .rd-navbar-nav {
      > li {
        padding: 1px 15px;

        @media (min-width: $screen-lg-min) {
          padding: 1px 19px;
          margin-left: 3px;
          margin-right: 2px;
        }

        > a {
          font-size: 16px;
          font-weight: 400;
          color: $white;
          padding: 0;
          &:before{
            position: absolute;
            top: calc( 100% + 1px);
            left: 0;
            right: 100%;
            content: "";
            display: inline-block;
            border-top: 2px solid;
            animation: out .3s;
          }
        }
        &.active,
        &.focus {
          > a {
            background: transparent;
            &:before{
              animation: in .3s forwards;
            }
          }
        }

        a {
          &:hover {
            background: transparent;
            &:before{
              animation: in .3s forwards;
            }
          }
        }

        [data-x-mode="true"] & {
          > .rd-navbar-submenu-toggle {
            &:before {
              right: -13px;
              color: $white;
            }
          }
        }
      }
    }

    .rd-navbar-top-panel-inner, .rd-navbar-inner {
      justify-content: space-between;
      max-width: 1920px;
      padding-left: 25px;
      padding-right: 25px;
    }

    .rd-navbar-aside-right {
      width: auto;
      margin-top: 0;
    }

  }

  &.rd-navbar-static {
    .rd-navbar-aside-right {
      width: auto;


      .list-inline {
        .icon {
          color: $white;
          &:hover{
            color: $primary;
          }
        }
      }
    }
    .rd-navbar-dropdown {
      top:calc(100% + 30px);
    }
  }

  &.rd-navbar--is-stuck {
    background-color: $primary;
    padding-left: 0;
    padding-right: 0;
    .rd-navbar-aside-right {
      .list-inline {
        .icon {
          &:hover{
            color: $gray-dark;
          }
        }
      }
    }
  }
}