//
// Icons
// --------------------------------------------------

// Base styles
// --------------------------------------------------

.icon {
  display: inline-block;
  font-size: 16px;
  line-height: 16px;
  transition: 250ms ease-in;

  &:before {
    position: relative;
    display: inline-block;
    font-weight: 400;
    font-style: normal;
    speak: none;
    text-transform: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}

// Alternate icons
// --------------------------------------------------

.icon-default {

}

.icon-primary {
  color: $primary;
  &.icon-circle {
    border-color: $primary;
  }

  &:hover {

  }
  &.icon-circle {

    &:hover {
      color: $white;
      background-color: $primary;
      box-shadow: 0 0 0 8px #c5d5e6;
    }
  }
}

.icon-white {
  color: $white;
  &.icon-circle {
    border-color: $white;
  }

  &:hover {

  }
  &.icon-circle {

    &:hover {
      color: $primary;
      background-color: $white;
    }
  }
}

.page {
  .icon-gray-2 {
    color: $gray-2;
  }
}



// Button Sizes
// --------------------------------------------------

.icon-xs {
  font-size: 15px;

  &.icon-circle {
    width: 38px;
    height: 38px;
    line-height: 36px;
  }
}

.icon-sm {
  font-size: 21px;
}

.icon-md {
  font-size: 24px;
  line-height: 24px;
}

svg.icon-md {
  width: 55px;
  height: 40px;
}

svg.icon-lg {
  width: 55px;
  height: 45px;
  use{
    transform: scale(1.3);
  }

}

.icon-lg {
  font-size: 30px;
}

.icon-circle {
  border-radius: 50%;
  border: 1px solid;
  text-align: center;
}

// Box icons
//--------------------------------------------------------

.box-icon {
  @media (min-width: $screen-sm-min) {
    padding-right: 35px;
  }
}

.box-icon-mod-1 {
  background-color: $gray-lightest;
  border-top: 1px solid $white;
  border-right: 1px solid $white;
  padding: 30px 15px;

  @media (min-width: $screen-xl-min) {
    padding: 50px 100px;
  }

  * + .button-link {
    margin-top: 10px;
  }

  p {
    max-width: 480px;
    margin-left: auto;
    margin-right: auto;
  }

  .button-link {
    font-weight: 600;
    &:after {
      content: none;
    }
  }
}

.box-icon-mod-2{
  h5 + .big {
    margin-top: 10px;
  }
}